import React, { useState } from "react";
import "./ImageComponent.scss";
export default function ImageComponent(props) {
    const [Showing, setShowing] = useState(false)

    const handleShowDialog = () => {
        setShowing(!Showing)
    };
    return (
        <div className="image">
            <img
                className="imgresults"
                src={props.img}
                onClick={handleShowDialog}
                alt=""
            />
            <span className="subtitle">{props.imagetype}</span>
            {Showing && (
                <div className="dialogOverlay" onClick={handleShowDialog}>
                    <dialog
                        className="dialog"
                        open
                        onClick={handleShowDialog}
                    >
                        <img
                            className="modalImage"
                            src={props.img}
                            onClick={handleShowDialog}
                            alt=""
                        />
                    </dialog>
                </div>
            )}
        </div>
    )
}
