import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import AuthPage from './pages/Authpage';
import Homepage from './pages/Homepage';
import './App.css';

class App extends Component {
	render() {
		return (
			<Router>
				<div>
					<Switch>
						<Route exact path="/login" component={AuthPage} />
						<Route exact path="/" component={Homepage} />
						<Redirect from="/" to="/login" />
					</Switch>
				</div>
			</Router>
		);
	}
}

export default App;
