import React, { useState, useEffect } from "react";
import Helper from "../services/helperservice";
import "./Homepage.scss";
import LOGO_IMG from "../assets/schenkerlogo.png";
import logout_img from '../assets/logout.png'
import SearchItem from "../components/SearchItem";
import { withRouter } from 'react-router-dom'
function Homepage(props) {
  const [kolliID, setkolliID] = useState("");
  const [searchData, setsearchData] = useState(false);
  const [value, setValue] = useState(
    JSON.parse(localStorage.getItem("Userdata")) || ""
  );

  const handleSubmit = event => {
    event.preventDefault();
    Helper.search(kolliID, value.access_token).then(data => {
      console.log(data);
      setsearchData(data);
    });
  };
  useEffect(() => {
    if (!value) {
      props.history.push("/login");
    } else if (value) {

      let expireDate = new Date(value.expires_at);
      if (expireDate > new Date()) {
        return;
      } else if (expireDate.Date === new Date().Date) {
        localStorage.removeItem("Userdata");
        props.history.push("/login");
      }
    }
  });
  const logout = () => {
	localStorage.removeItem("Userdata")
	props.history.push("/login");
  }
  return (
    <div className="homeWrapper">
		<img className="logout" src={logout_img} onClick={logout} alt=""/>
      <div className="homeContainer">
        <img className="logoImg" src={LOGO_IMG} alt="" />
        <div className="container">
          <div className="searchwrapper">
            <p>Reklamationer</p>
            <form onSubmit={handleSubmit}>
              <input
                placeholder="Kolli-id"
                type="text"
                className="inputfield"
                value={kolliID}
                onChange={e => setkolliID(e.target.value)}
              />

              <input className="submitbutton" type="submit" value="Sök" />
            </form>
          </div>
          {searchData
            ? searchData.map((item, i) => {
                return <SearchItem {...item} key={i} />;
              })
            : null}
        </div>
      </div>
    </div>
  );
}
export default withRouter(Homepage)