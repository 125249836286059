import './SearchItem.scss';
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom'
import ImageComponent from './ImageComponent'
import Helper from '../services/helperservice'
export default function SearchItem(props) {

    const [showing, setShowing] = useState(false)
    function showDetails() {
        setShowing(!showing)
    }
    return (
        <div className="searchResults" >
            <div className="header" onClick={showDetails}>
                <div className="infosection">
                    <p className="title">Sändningsnummer</p>
                    <p className="results">{props.shippingNo}</p>
                </div>
                <p className="title">{props.date} {props.time} </p>
            </div>
            {showing ?

                <div className="content">
                    <div className="infosection">
                        <label>Typ</label>
                        <p className="results">{Helper.TypeCases(props.type)}</p>
                    </div>
                    <div className="infosection">
                        <label>Skede</label>
                        <p className="results">{Helper.PhaseCases(props.phase)}</p>
                    </div>
                    <div className="infosection">
                        <label>Skada</label>
                        <p className="results">{Helper.damageCases(props.damage)}</p>
                    </div>
                    <div className="infosection">
                        <label>Transportör</label>
                        <p className="results">{props.shipper}</p>
                    </div>
                    <div className="infosection">
                        <label>Användare</label>
                        <p className="results">{props.signature}</p>
                    </div>
                    <div className="infosection">
                        <label>Saknade Kollin</label>
                        <p className="results">{props.missingPackages}</p>
                    </div>
                    
                    <div className="infosection">
                        <label>Beskrivning</label>
                        <p className="results">{props.description}</p>
                    </div>
                    <div className="infosection infoImages">
                        <label >Bilder</label>
                        <div className="images">

                            {props.signatureImage ? <ImageComponent img={'http://schenker-scanner.staging1.toxicdev.se/' + props.signatureImage} imagetype={"Signatur"} /> : null}
                            {props.otherPhoto ? <ImageComponent img={'http://schenker-scanner.staging1.toxicdev.se/' + props.otherPhoto} imagetype={"Signatur"} /> : null}
                            {props.otherPhoto2 ? <ImageComponent img={'http://schenker-scanner.staging1.toxicdev.se/' + props.otherPhoto2} imagetype={"Signatur"} /> : null}
                            {props.shippingNoPhoto ? <ImageComponent img={'http://schenker-scanner.staging1.toxicdev.se/' + props.shippingNoPhoto} imagetype={"Streckkod"} /> : null}
                        </div>
                    </div>
                </div>
                : null}
        </div>
    );
}