import React, { useState, useEffect } from 'react';
import './Authpage.scss';
import { withRouter } from 'react-router-dom'
import Auth from '../services/helperservice'


function Authpage(props) {
    const [userName, setuserName] = useState('')
    const [password, setPassword] = useState('')
    const [ErrorMessage, setErrorMessage] = useState('')

    useEffect(()=>  {
		let data = localStorage.getItem('Userdata')
		if(!data)
		{
            return
        }
        else if(data && data.expires_at > new Date()){
            console.log('yes')
            props.history.push('/')
        }
	})
    const handleSubmit = (event) => {
        event.preventDefault()
        Auth.login(userName, password).then(data => {
            console.log(data)
            if(data){
                localStorage.setItem('Userdata', JSON.stringify(data))
                props.history.push('/')
            }
            else{
                setErrorMessage('Fel användarnamn eller lösenord, försök igen')
            }
        })
        .catch((error) => {
            setErrorMessage(error)
        })
    }

    return (
        <div className="AuthWrapper">
            <form onSubmit={handleSubmit} className="loginwrapper">
                <p>DB Schenker</p>
                <input
                    placeholder="E-post"
                    type="email"
                    className="inputfield"
                    value={userName}
                    onChange={e => setuserName(e.target.value)}
                />

                <input
                    placeholder="lösenord"
                    type="password"
                    className="inputfield"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                />
                <input className="submitbutton" type="submit" value="Logga in" />
            </form>
            {ErrorMessage.length ? <p className="error">{ErrorMessage}</p>:null}  
        </div>
    );
}
export default withRouter(Authpage)
