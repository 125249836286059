import { LOGIN_URL, SEARCH_URL, LOGIN_DEV, SEARCH_DEV } from './globalsettings';

function objectToFormData(obj) {
    return Object.keys(obj)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key])).join('&')
}

async function login(username, password) {

    const response = await fetch(`${LOGIN_URL}/auth/authenticate`,
        {
            method: 'POST',
            headers:
            {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                Accept: 'application/json'
            },
            body: objectToFormData({
                grant_type: 'password',
                username,
                password
            })
        })
    let content = await response.json();
    if (response.ok) {
        return content;
    }
    else if (response.status === 401 || response.status === 500) {
        return { ok: false }
    }
}
function search(id, token) {
    return fetch(`${SEARCH_URL}/api/kollisearch?shippingno=${id}`,
        {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer ' + token
            },
        })
        .then(res => res.json())
        .then(response => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        })
}
function damageCases(number) {
    let damageType = number.toString()
    switch (damageType) {
        case '-1':
            return "Inga skador"

        case '0':
            return "Stort hål"

        case '1':
            return "Litet hål"

        case '2':
            return "Stor reva"

        case '3':
            return "Liten reva"

        case '4':
            return "Öppen"

        case '5':
            return "Blöt"

        case '6':
            return "Kantstött"

        case '7':
            return "Blött"

        case '8':
            return "Tilltryckt"

        case '9':
            return "Klämd"

        case '10':
            return "Böjd"

        case '11':
            return "Bristfälligt emballage"

        default:

    }
}
function TypeCases(number) {
    let Type = number.toString()
    switch (Type) {
        case '0':
            return "Skada"

        case '1':
            return "Manko"
        default:

    }
}
function PhaseCases(number) {
    let Type = number.toString()
    switch (Type) {
        case '0':
            return "(Ik) Kund"

        case '1':
            return "(Ih) Hämtningsbil"

        case '2':
            return "(If) Fjärrtransportör"

        case '3':
            return "(Um) Utlast från magasin"
        default:

    }
}
export default {
    login,
    search,
    damageCases,
    TypeCases,
    PhaseCases
}